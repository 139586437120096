<template>
    <v-container fluid class="pa-0">
        <v-toolbar elevation="1" :style="stickyTop">
            <v-toolbar-title>Users</v-toolbar-title>
        </v-toolbar>
        <v-container>
            <v-data-table :items="this.users" :headers="headers" :disable-sort="this.$vuetify.breakpoint.mobile"
                :loading="loading" item-key="uid" disable-pagination hide-default-footer no-data-text="" no-results-text="">
            </v-data-table>
        </v-container>
    </v-container>
</template>

<script>
import { functions } from "@/services/firebase";
import { httpsCallable } from "firebase/functions";

export default {
    name: "Users",
    data() {
        return {
            loading: false,
            users: [],
            tableHeight: null,
        };
    },
    computed: {

        headers() {
            return [
                { text: "User-id", value: "uid" },
                { text: "DisplayName", value: "displayName" },
                { text: "Email", value: "email" },
                { text: "Rol", value: "customClaims.role" },
                { text: "Rol-Id", value: "id" },
            ];
        },
        stickyTop() {
            return {
                position: "sticky",
                top: this.$vuetify.application.top + "px",
                zIndex: 1,
            };
        },
    },
    methods: {
        async fetchUsers() {
            const listUsersWithCustomClaims = httpsCallable(functions, 'listUsersWithCustomClaims');

            listUsersWithCustomClaims({ maxResults: 500 })
                .then((result) => {
                    this.users = result.data.users
                    //console.log('Users list:', result);
                })
                .catch((error) => {
                    console.error('Error fetching users:', error.message);
                });
        },

    },
    created() {
        this.fetchUsers();
    },
};
</script>

